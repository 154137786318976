import React from "react";
import { ContentBox, HeaderText, HeroContainer, Text } from "../blogPageElements";
import Footer from "../../Footer";

const BlogPage3 = () => {
    return (
        <HeroContainer id="roleofuxinstem">
            <ContentBox>
                <HeaderText>The Role of UX in STEM</HeaderText>
                <Text>I'm still being written! Check back soon!</Text>
            </ContentBox>
        </HeroContainer>
    )
}

export default BlogPage3