import React from "react";
import { ContentBox, HeaderText, HeroContainer, Text } from "../blogPageElements";
import Footer from "../../Footer";

const BlogPage4 = () => {
    return (
        <HeroContainer id="innovationinthedigitalspace">
            <ContentBox>
                <HeaderText>Innovation in the Digital Space</HeaderText>
                <Text>I'm still being written! Check back soon!</Text>
            </ContentBox>
        </HeroContainer>
    )
}

export default BlogPage4