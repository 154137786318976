import styled from 'styled-components'

export const HeroContainer = styled.div`
    background: white;
    width: 100%;
    height: fit-content;
    position: absolute;
    align-items: center;
    padding-top: 12vh;
    padding-left: 8vw;
    padding-right: 8vw;
`

export const ContentBox = styled.div`
    width: 84vw;
    display: flex;
    flex-direction: column;
`

export const HeaderText = styled.p`
    color: #787878;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 100;
    line-height: 150%;
`

export const Text = styled.p`
    color: #787878;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 100;
    line-height: 120%;
`