import React from "react";
import { ContentBox, HeaderText, HeroContainer, Text } from "../blogPageElements";
import Footer from "../../Footer";

const BlogPage6 = () => {
    return (
        <HeroContainer id="sustainabledigitaldesignpractices">
            <ContentBox>
                <HeaderText>Sustainable Digital Design Practices</HeaderText>
                <Text>I'm still being written! Check back soon!</Text>
            </ContentBox>
        </HeroContainer>
    )
}

export default BlogPage6